import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

const mutationQuery = `
  mutation Preregister($emailAddress: String!) {
    preRegisterUser(input: {
      emailAddress: $emailAddress
    })
  }
`

const PreregisterForm = ({ ctaText, title }) => {
  const { urls, fathomPreregisterId } = useStaticQuery(graphql`
    query PreregisterForm {
      site {
        siteMetadata {
          urls {
            api
          }
          fathomPreregisterId
        }
      }
    }
  `).site.siteMetadata
  const [emailAddress, setEmailAddress] = useState("")
  const [showAlert, setShowAlert] = useState(false)

  const onSubmit = e => {
    e.preventDefault()
    if (emailAddress === "") {
      return
    }

    if (window.fathom !== undefined && fathomPreregisterId) {
      window.fathom("trackGoal", fathomPreregisterId, 0)
    }

    fetch(urls.api, {
      method: "POST",
      body: JSON.stringify({
        query: mutationQuery,
        variables: {
          emailAddress,
        },
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(resp => resp.json())
      .then(({ data }) => {
        if (data && data.preRegisterUser === true) {
          setShowAlert(true)
          setEmailAddress("")
        }
      })
      .catch(err => console.error(err))
  }

  return (
    <div className="container mb_90">
      <div className="subscribe_form_info s_form_info_two text-center">
        <h2 className="f_600 f_size_30 l_height30 t_color3 mb_50">{title}</h2>
        <form className="subscribe-form" onSubmit={onSubmit}>
          {showAlert && (
            <div className="alert alert-success">
              Check your email to finish registering!
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlert(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <input
            type="email"
            className="form-control"
            placeholder="e.g. mary@hookactions.com"
            value={emailAddress}
            onChange={e => setEmailAddress(e.target.value)}
            required={true}
          />
          <button type="submit" className="btn_hover btn_four mt_40">
            {ctaText}
          </button>
        </form>
      </div>
    </div>
  )
}

PreregisterForm.defaultProps = {
  title: "Get started with HookActions",
  ctaText: "Sign up",
}

export default PreregisterForm
