import React from "react"
import { graphql } from "gatsby"
import Reveal from "react-reveal/Reveal"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PreRegister from "../components/Preregsiter"

const IntegrationCliPage = ({ data }) => {
  const { urls } = data.site.siteMetadata
  const { title, image, commands } = data.markdownRemark.frontmatter

  return (
    <Layout navClass="w_menu" headerClass="transparent_header">
      <SEO
        title={`${title} Webhook CLI`}
        description={`Easily manage ${title} webhooks using the command line.`}
      />
      <section className="breadcrumb_area pb-5">
        <img
          className="breadcrumb_shap"
          src={require("../images/breadcrumb/banner_bg.png")}
          alt=""
        />
        <div className="container">
          <div className="breadcrumb_content text-center">
            <Reveal effect="fadeInUp">
              <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">
                {title} Webhook CLI
              </h1>
            </Reveal>
            <a
              href={urls.cliDownload}
              className="btn_get btn_hover saas_btn mt-4"
            >
              Download CLI
            </a>
          </div>
        </div>
      </section>
      <section className="agency_service_area  pt-5">
        <div className="container">
          <div className="row justify-content-center align-items-center mb-5">
            <div className="col-md-6">
              <img
                className="img-fluid rounded"
                src={require("../images/" + image)}
                alt={title}
              />
            </div>
            <div className="col-md-6">
              <h2 className="f_size_30 f_300 t_color3 l_height40 text-center mb_90">
                The HookActions CLI allows easy access to test {title} webhooks
                by sending events to your local server
              </h2>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div id="termynal" data-termynal className="text-left shadow w-100">
              {commands.map(({ text, input }, i) => (
                <span data-ty={input ? "input" : ""}>{text}</span>
              ))}
            </div>
          </div>

          <div className="text-center mt-5">
            <a
              href={urls.register}
              className="seo_btn seo_btn_one btn_hover mr-2"
            >
              Create an account
            </a>
            <a
              href={urls.cliDownload}
              className="seo_btn seo_btn_two btn_hover"
            >
              Download CLI
            </a>
          </div>
        </div>
      </section>

      <PreRegister title={`Get started with ${title} webhooks`} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
        image
        commands {
          text
          input
        }
      }
    }

    site {
      siteMetadata {
        urls {
          register
          cliDownload
        }
      }
    }
  }
`

export default IntegrationCliPage
